<template>
    <div class="container">
        <p class="title" style="font-size: 28px; margin-bottom: 10px;">赏秋赏月享祝愿</p>
        <p class="time" style="font-size: 18px; margin-bottom: 20px;">2021-09-19 11:28</p>
        <img src="../../static/img/consultCenterImg/home3.png" style="width: 60vw; margin: auto; display: block; margin-bottom: 25px;" alt="">
        <p class="text" style="margin-bottom: 25px;">在中秋佳节来临之际，为感谢各位职工工作的辛勤付出，公司提前为全体员工发放中秋节慰问礼品，送上公司的关怀与节日的祝福！</p>
        <div class="grid">
            <img src="../../static/img/consultCenterImg/news-3/1.png" style="width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-3/2.png" style="width: 80%; display: block; margin: auto;" alt="">
        </div>
        <p class="text" style="margin-bottom: 25px;">2021年9月17日下午各部门负责人来到指定地点领取福利！从职工笑脸看出心里美滋滋的！</p>
        <div class="grid">
            <img src="../../static/img/consultCenterImg/news-3/3.png" style="width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-3/4.png" style="width: 80%; display: block; margin: auto;" alt="">
        </div>
        <p class="text" style="margin-bottom: 25px;">月是中秋分外明，皓月当空洒清辉，在这个中秋佳节来临之际，祝各位工作顺利、万事如意，过一个美好的佳节！</p>
    </div>
</template>
<script>
export default {created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped>
    * {
        padding: 0;
        box-sizing: border-box;
        margin: 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: bold;
        color: #000000;
    }

    .title, .time{
        display: block;
        margin: auto;
        text-align: center;
    }

    .text{
        font-size: 16px;
        text-indent: 2em;text-align: center;
    }

    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 20px;
        width: 90%;
        margin: auto;
        margin-bottom: 25px;
    }
</style>